import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"
import PageContentPath from "../components/PageContentPath"

import { isMobile } from "react-device-detect"
import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {

    var next_visual = require('../images/home/project_d.png');

    return (

      <div className="content_wrap project_c_4_wrap">

        <div className="page_header">
          <h2 className="sub_title">駐村編輯</h2>
          <h1 className="main_title">社區藝術走讀</h1>
          <h6 className="speaker">策展人/林書豪 導覽人/魏子鈞</h6>
        </div>

        <div className="page_content project_c_4_3">

          <section className="row_area_s project_c_4_3_a">
            <div className="column_4">
              <div className="context">
                <p>重訪萬華「2019堀江町藝術季」了解藝術與社區的生活樣貌關係，共同想像未來的萬華莒光公宅。</p>
                {/* <p className="tag"><i>#萬華老故事 #文化保存 #新舊發展</i></p> */}
                <p><strong>路線</strong> - <i>臺北市婦女館→臺北市立圖書館西園分館→頂碩里公佈欄→雙園里辦公室→ Lafu House 愛氏司康→莒光路283巷→萬華社區小學→闔記服裝行→和平青草園</i></p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_b project_c_4_head row_break">
            <div className="column_5 onset_left_1">
              <figure className="figure size_full main_map">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_map.jpg")} alt="社區藝術走讀"/>
              </figure>
            </div>
            <div className="column_2 leader">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_lead.jpg")} alt="社區藝術走讀"/>
                <figcaption className="figcaption right">魏子鈞<br/>在地文化工作者</figcaption>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_c row_gallery">
            <div className="column_4 onset_left_2 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_1.jpg")} alt="社區藝術走讀"/>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_c row_gallery">
            <div className="column_4 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_4.jpg")} alt="三個里的布告欄"/>
              </figure>
              <div className="column_6">
                <div className="context_s">
                  <p>萬華堀江町藝術季其中一件作品是將民眾創作的版畫放在三個里的布告欄上，讓觀眾可以在大街小巷尋寶。QR Cord掃描進去可以聽見民眾自己口述故事。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_d row_gallery">
            <div className="column_5 row_area_s onset_left_3">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_3.jpg")} alt="台北市婦女館"/>
              </figure>
              <div className="column_6">
                <div className="context_s">
                  <p>位於台北市婦女館的展點，邀請藝術家廢青不廢，針對不同婦女的生命故事，用回收塑膠袋編織出可穿戴的飾品，將每個傷痛轉化成身上的美麗花朵，作品名為《逆境繁花》。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_e row_gallery">
            <div className="column_4 onset_left_2 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_5.jpg")} alt="萬華社區小學與闔記服裝行合作"/>
              </figure>
              <div className="column_6 onset_left_2">
                <div className="context_s">
                  <p>萬華社區小學與闔記服裝行合作，邀請民眾利用剩布製作出新的衣服，甚至最後舉辦了一場走秀，增進里民之間的感情。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_f row_break">
            <div className="column_3">
              <div className="context">
                <h5>社區藝術想像工作坊</h5>
                <p>現場一邊走一邊拍一邊聽，將攝影照片輸出，剪貼成新的影像拚貼作品，表達藝術社區的想像圖。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_g">
            <div className="column_3 onset_left_4 offset_right_1 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_6.jpg")} alt="社區藝術想像工作坊"/>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_h">
            <div className="column_5 onset_left_1 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_8.jpg")} alt="建築"/>
                <figcaption className="figcaption main left"><div className="text_inner">建築</div></figcaption>
              </figure>
              <div className="column_4 onset_left_4">
                <div className="context_s">
                  {/* <h5>建築</h5> */}
                  <p>有藝術的空間，往後走仍保有傳統的三合院，貓咪自在地在其中穿梭跑跳，城市的紋理在新舊交融之間是迷人的狀態。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_i row_gallery">
            <div className="column_5 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_9.jpg")} alt="我心目中的美好畫面"/>
                <figcaption className="figcaption main center"><div className="text_inner">我心目中的美好畫面</div></figcaption>
              </figure>
              <div className="column_6">
                <div className="context_s">
                  {/* <h5>我心目中的美好畫面</h5> */}
                  <p>平安健康、環境綠意盎然，就是日常最好的畫面。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_i row_gallery">
            <div className="column_5 onset_left_2 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_3/project_c_4_3_10.jpg")} alt="市場樂園"/>
                <figcaption className="figcaption main left"><div className="text_inner">市場樂園</div></figcaption>
              </figure>
              <div className="column_4">
                <div className="context_s">
                  {/* <h5>我心目中的美好畫面</h5> */}
                  <p>菜市場是最生猛有活力的場域，若是能拓展市場的上下空間，加入藝術在其中，或許藝術就不會離人們那麼遠了，市場也能更有趣!</p>
                </div>
              </div>
            </div>
          </section>

          {!isMobile ? <PageContentPath /> : null}

        </div>


        <div className="page_footer">
          <Link to="/project_d" className="next_page">
            <div className="next_title">養一隻植物和你說說話</div>
            <div className="next_owner">策展人/賴映如</div>
            <div className="next_visual" style={ { backgroundImage: "url(" + next_visual + ")" } }></div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
