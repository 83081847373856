
import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import { Router, Link } from "@reach/router"

import { isMobile } from "react-device-detect";
import window from 'global'

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {

    var wrap = document.querySelector('#wrap');
    wrap.style.height = 'initial';
    wrap.style.overflowX = 'initial';

    var bg_path = document.querySelector('.bg_path');
    var main_map = document.querySelector('.main_map');
    var page_content = document.querySelector('.page_content');
    var project_c_4_head = document.querySelector('.project_c_4_head');

    bg_path.style.top = main_map.offsetHeight * .25  + project_c_4_head.offsetTop + 'px';
    // bg_path.style.height = page_content.offsetHeight - main_map.offsetHeight / 1.5 + 'px';

    var path = document.querySelector('.main_path');
    var pathLength = path.getTotalLength();

    path.style.strokeDasharray = pathLength + ' ' + pathLength;
    path.style.strokeDashoffset = pathLength;
    path.getBoundingClientRect();
   
    window.addEventListener("wheel", function(e) {

      bg_path.classList.add('in');
      // bg_path.style.top = main_map.offsetHeight * .25  + project_c_4_head.offsetTop + 'px';

      var scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * .9;
      var drawLength = pathLength * scrollPercentage;      

      path.style.strokeDashoffset = (pathLength - drawLength);
        
      //  gsap.to(path, {
      //    direction: 1,
      //    strokeDashoffset: pathLength - drawLength,
      //    ease: Power1.easeIn,
      //  });

      if (scrollPercentage >= 0.99) {
        path.style.strokeDasharray = "none";
      } else {
        path.style.strokeDasharray = pathLength + ' ' + pathLength;
        // gsap.to(path, {
        //   // opacity: 0,
        //   // delay: .3,
        //   // direction: 1,
        //   strokeDashoffset: pathLength + ' ' + pathLength,
        //   ease: Expo.easeIn,
        // });
      }
    });
      
  }
 
  componentWillUnmount() {

  }


  render() {

      return (

         <div className="bg_path">
           <svg xmlns="http://www.w3.org/2000/svg" width="72" height="1000" x="0" y="0" enableBackground="new 0 0 72.67 1000" viewBox="0 0 72.67 1000" className="bg_path_svg">
             <path d="M45.65,4.34c-9,15.52-40.35,75.44-23.6,131.33c11.31,37.74,56.22,94.06,60.59,154.51 c4.43,61.19-0.11,91.21-9.95,119.89C47.23,484.23-4.96,489.68,0.96,475.4c11.14-26.85,52.85,55.8,42.06,107.81 C26.78,661.5,54.68,728.96,80.35,775.15c57.66,103.76-20.5,186.85-27.56,222.9" className="main_path"></path>
             {/* <path d="M45.65,4.34c-9,15.52-40.35,75.44-23.6,131.33c11.31,37.74,56.22,94.06,60.59,154.51 c4.43,61.19-0.11,91.21-9.95,119.89C47.23,484.23-4.96,489.68,0.96,475.4c11.14-26.85,52.85,55.8,42.06,107.81 C26.78,661.5,54.68,728.96,80.35,775.15c57.66,103.76-20.5,186.85-27.56,222.9" className="sub_path"></path> */}
           </svg>
         </div>
      )

   }

}


export default Page

  